<template>
  <div class="lottery-page">
    <img class="love-girl" src="@/assets/website-use/love.png" alt="LoveGirl" />
    <img
      class="need-girl"
      src="@/assets/website-use/needThisCoolGuy.png"
      alt="NeedThisCoolGuy"
    />
    <div class="title">
      {{ drawingData.name }}
    </div>
    <div class="tw-container">
      <div class="content">
        <!-- 抽獎期限 -->
        <div class="mb-3">
          <div class="d-flex justify-content-between mb-2">
            <div>
              <p class="tw-border-start fw-bolder">抽獎期限</p>
            </div>
            <a
              class="tw-btn tw-btn tw-btn-secondary tw-btn-hover"
              href="/customer/storeLists"
              >返回個人頁面</a
            >
          </div>
          <div class="d-flex justify-content-between"></div>

          <div class="alert alert-primary">
            <div class="fw-bolder text-center d-flex justify-content-between">
              <div class="col-md-5">
                開始時間：{{
                  this.$methods
                    .moment(drawingData.enableTime)
                    .format("YYYY-MM-DD HH:mm:ss")
                }}
              </div>
              ～
              <div class="col-md-5">
                結束時間：{{
                  this.$methods
                    .moment(drawingData.expirationTime)
                    .format("YYYY-MM-DD HH:mm:ss")
                }}
              </div>
            </div>
          </div>
        </div>
        <!-- 抽獎描述 -->
        <div class="mb-3">
          <p class="tw-border-start fw-bolder mb-2">抽獎描述</p>
          <div class="border border-1 p-2">
            <div
              v-if="drawingData.description !== null"
              v-html="drawingData.description"
              style="height: calc(100vh - 40vh); overflow-y: scroll"
            ></div>
            <p v-else class="fw-bolder text-danger text-center">無活動描述</p>
          </div>
        </div>
        <!-- 參加抽獎 -->
        <p class="fw-bolder text-danger text-center" v-if="!canDraw">
          您已參加抽獎!!
        </p>
        <div class="text-center" v-else @click="joinDraw">
          <img
            class="addOneA"
            src="@/assets/website-use/addOneA.png"
            alt="addOneA"
          />
          <button
            id="SubmitBtn"
            class="submit tw-btn tw-btn-hover"
            type="button"
          >
            參加抽獎
          </button>
          <img
            class="addOneB"
            src="@/assets/website-use/addOneB.png"
            alt="addOneB"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // data
      serverToken: "",
      storeId: 0,
      drawingId: null,
      drawingData: [],
      canDraw: true,
    };
  },
  created() {
    this.initialization();
    this.verify(); // 審核抽獎狀態 (是否已參加抽獎)
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie("serverToken");
      this.storeId = this.$route.params.storeId;
      this.drawingId = this.$route.query.drawingId;
      let examine = {
        canEnter: false,
        show: "",
      };
      // 無 drawingId
      if (!this.drawingId) examine.show = "查無此抽獎活動";
      else {
        this.getStoreDrawing([this.drawingId]);
        // 有 drawingId
        // 無此抽獎活動
        if (this.drawingData.length === 0) examine.show = "查無此抽獎活動";
        else {
          // 有此抽獎活動
          this.drawingData = this.drawingData[0];
          if (this.drawingData.description)
            this.drawingData.description = this.drawingData.description.replace(
              /(\r\n)|(\n)/g,
              "<br>"
            );
          const enableTime = this.drawingData.enableTime;
          const expirationTime = this.drawingData.expirationTime;
          const nowTime = new Date() * 1;
          if (nowTime < enableTime) examine.show = "抽獎活動尚未開始";
          else if (nowTime > expirationTime) examine.show = "抽獎活動已截止";
          else examine.canEnter = true;
        }
      }
      if (!examine.canEnter) {
        alert(examine.show);
        return this.$router.push("/customer/storeLists");
      }
    },
    // 審核抽獎狀態
    verify() {
      const vm = this;
      const verifyUserApi = `${process.env.VUE_APP_API}/participant/parseToken?storeId=${this.storeId}&token=${this.serverToken}`;
      $.ajax({
        type: "GET",
        async: false,
        url: verifyUserApi,
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            const participantInfo = res.data;
            vm.drawingData.storeDrawingParticipants.some((item) => {
              if (item.participantId === participantInfo.id) {
                vm.canDraw = false;
                return true;
              }
            });
            console.log(vm.canDraw);
          }
        },
        error: function (err) {
          console.log(err.responseJSON);
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message);
          vm.$methods.switchLoading("hide");
        },
      });
    },
    // 取得抽獎資料 (抽獎 ID)
    getStoreDrawing(drawingId) {
      this.$methods.switchLoading("show");
      const vm = this;
      const getStoreDrawingApi = `${process.env.VUE_APP_API}/search/getSiteObjects`;
      const header = {
        authorization: this.serverToken,
      };
      let query = {
        columns: [{ columnName: "id", siteObjectType: 67 }],
        distinct: true,
        group: {
          groups: [
            {
              column: { columnName: "storeId", siteObjectType: 67 },
              operator: { operator: "=", type: 1, value: this.storeId },
            },
            {
              column: { columnName: "id", siteObjectType: 67 },
              operator: { type: 3, value: drawingId },
            },
          ],
          operator: 1,
        },
        joins: [],
        siteObjectType: 67,
        sqlCommandType: 1,
      };
      const data = [
        {
          query: query,
          methods: "{getStoreDrawingParticipants}",
        },
      ];
      $.ajax({
        type: "POST",
        async: false,
        url: getStoreDrawingApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: "application/json",
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            console.log("抽獎資料:", res.data[0].objects);
            vm.drawingData = res.data[0].objects;
          }
          vm.$methods.switchLoading("hide");
        },
        error: function (err) {
          console.log(err.responseJSON);
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message);
          vm.$methods.switchLoading("hide");
        },
      });
    },
    // 參加抽獎活動，傳送API
    joinDraw() {
      this.$methods.switchLoading("show");
      const vm = this;
      const joinInDrawApi = `${process.env.VUE_APP_API}/storeDrawing/participate`;
      const header = {
        authorization: this.serverToken,
      };
      const data = new FormData();
      data.append("ids", [this.drawingId]);
      $.ajax({
        type: "POST",
        async: true,
        url: joinInDrawApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === "200") {
            vm.SweetAlert(res.code, res.message);
            vm.canDraw = false;
            return vm.$router.push("/customer/storeLists");
          } else {
            vm.SweetAlert(res.code, res.message);
          }
          vm.$methods.switchLoading("hide");
        },
        error: function (err) {
          console.log(err.responseJSON);
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message);
          vm.$methods.switchLoading("hide");
        },
      });
    },
  },
};
</script>
